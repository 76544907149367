// sign in/up and accounts
export const SIGN_IN_ROUTE = '/signin';
export const SIGN_UP_ROUTE = '/signup';
export const CLAIM_ACCOUNT_ROUTE = '/claim_account';
export const SSO_AUTH_ROUTE = '/api/v1/sso/auth';

// employees
export const DISCOVER_EVENTS_ROUTE = '/';
export const DISCOVER_CONTENTS_ROUTE = '/discover/contents';
export const DISCOVER_COMMUNITIES_ROUTE = '/discover/communities';
export const EVENT_ROUTE = '/event';
export const EVENT_CHECKIN_ROUTE = '/event/checkin';
export const CONTENT_ROUTE = '/content';
export const PROFILE_ROUTE = '/profile';
export const COMMUNITY_ROUTE = '/community';
export const USER_ROUTE = '/u';
export const USER_SETTINGS_ROUTE = '/account-settings';
export const USER_SETTINGS_NOTIFICATIONS_ROUTE = '/account-settings/notifications';
export const USER_SETTINGS_PROFILE_ROUTE = '/account-settings/profile';

// dashboard - overview
export const DASHBOARD_ROUTE = '/dashboard';
export const INSIGHTS_DASHBOARD_ROUTE = '/dashboard/insights';
export const COMMUNITIES_CREATE_ROUTE = '/dashboard/communities/create';
export const COMMUNITIES_MANAGE_ROUTE = '/dashboard/communities/:communityId/manage';
export const COMMUNITIES_LIST_ROUTE = '/dashboard/communities/list';

export const EVENTS_LIST_ROUTE = '/dashboard/events/list';
export const EVENT_EDIT_ROUTE = '/dashboard/event/edit';
export const EVENT_INSIGHTS_ROUTE = '/dashboard/event/data/:id';
export const CONTENT_LIST_ROUTE = '/dashboard/contents/list';
export const CONTENT_EDIT_ROUTE = '/dashboard/content/edit';
export const CONTENT_INSIGHTS_ROUTE = '/dashboard/content/data/:id';
export const SURVEY_TEMPLATE_LIST_ROUTE = '/dashboard/templates/list';
export const SURVEY_TEMPLATE_EDIT_ROUTE = '/dashboard/templates/edit';
export const COMMUNICATIONS_LIST_ROUTE = '/dashboard/communications/list';
export const PROMOTE_CALENDAR_ROUTE = '/dashboard/promotions/calendar';
export const COMMUNICATIONS_SLACK_ROUTE = '/dashboard/communications/slack';

// dashboard - settings
export const COMPANY_ROUTE = '/dashboard/company';
export const TEAMS_ROUTE = '/dashboard/teams';
export const INTEGRATIONS_ROUTE = '/dashboard/integrations/integrations-setup';
export const CUSTOM_SETTINGS_ROUTE = '/dashboard/settings';
export const EMPLOYEE_LIST_ROUTE = '/dashboard/employees/manage';
export const EDIT_EMPLOYEE_LIST_ROUTE = '/dashboard/employees/update';
export const BAMBOOHR_ROUTE = '/dashboard/employees/bamboohr';

// callback url used by zoom integration
export const ZOOM_CALLBACK_ROUTE = '/zoomcallback';

export const ERROR_ROUTE = '/error';
